import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  BUTTON_TYPES,
  Input,
  notification,
  NOTIFICATION_TYPES,
  Popup,
  RangePicker,
  Table,
  Typography,
} from '_atoms';
import { TableMobile } from '_atoms/Table/Table';
import { PopupCandidateDuplicate, PopupReassignCandidate, TableFooter } from '_molecules';
import {
  bulkDeleteSSCAPI,
  deleteCandidate,
  getListHrManagers,
  getRecruiterSSCTableDashboardAPI,
  getSSCPotentialDuplicatesAPI,
  reviewCandidate,
} from 'actions';
import { ROLES } from 'permission';
import { getUser } from 'store/selectors/authorizeSelectors';
import { DeleteCandidatePopup } from '../CandidateDetails/CandidateDetails';
import { Empty } from './Empty';
import { INITIAL_VALUES_SSC, COLUMNS_SSC } from './constants';
import './style.scss';

export const SelfSubmittedCandidatesDashboard = ({ dashboard }) => {
  const dispatch = useDispatch();
  const user = useSelector(getUser);

  const [loading, setLoading] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [tableOptions, setTableOptions] = useState(INITIAL_VALUES_SSC);
  const [count, setCount] = useState(0);

  const [assignPopup, setAssignPopup] = useState(null);
  const [reviewPopup, setReviewPopup] = useState(null);
  const [deletePopup, setDeletePopup] = useState(null);
  const [potentialDuplicates, setPotentialDuplicates] = useState([]);

  const [openConfirmPopup, setOpenConfirmPopup] = useState(false);
  const [selectedRows, setSelectedRows] = useState([]);

  const [duplicatesPopup, setDuplicatesPopup] = useState(null);

  const onAssignPopupClose = () => setAssignPopup(null);

  const onReviewPopupClose = () => setReviewPopup(null);
  const onDeletePopupClose = () => setDeletePopup(null);
  const onDuplicatesPopupClose = () => {
    setDuplicatesPopup(null);
    setPotentialDuplicates([]);
  };

  const getDuplicates = (data, showMessage) => {
    getSSCPotentialDuplicatesAPI(data.uuid).then(duplicates => {
      setPotentialDuplicates(duplicates);
      if (showMessage && duplicates.length) {
        notification({
          message: `Found ${duplicates.length} duplicate(s).`,
          btn: (
            <span role="none" onClick={() => setDuplicatesPopup(data)}>
              Open Duplicates
            </span>
          ),
          type: NOTIFICATION_TYPES.WARNING,
        });
      }
    });
  };

  const onOpenConfirmPopup = () => {
    setOpenConfirmPopup(true);
  };
  const onCloseConfirmPopup = () => {
    setOpenConfirmPopup(false);
  };

  const onBulkDelete = () => {
    bulkDeleteSSCAPI(
      dashboard,
      selectedRows.map(r => r.candidate_id)
    )
      .then(() => {
        notification({ type: NOTIFICATION_TYPES.SUCCESS, message: 'Candidate(s) have been deleted.' });
        getTableData();
      })
      .finally(() => {
        onCloseConfirmPopup();
      });
  };

  const onAction = (action, data) => () => {
    switch (action) {
      case 'assign':
        getDuplicates(data, true);
        setAssignPopup(data);
        break;
      case 'review':
        getDuplicates(data, true);
        setReviewPopup(data);
        break;
      case 'share':
        if (navigator.clipboard) {
          navigator.clipboard
            .writeText(`${window.location.origin}/candidates/preview/${data.candidate_id}`)
            .then(() => notification({ message: 'Link to profile has been copied', type: NOTIFICATION_TYPES.SUCCESS }));
        }
        break;
      case 'delete':
        setDeletePopup(data);
        break;
      case 'duplicates':
        getDuplicates(data);
        setDuplicatesPopup({ ...data, first_name_latin: data.full_name_latin, last_name_latin: data.full_name_latin });
    }
  };

  const onChangeFilter = (field, item) => {
    if (field === 'page') {
      setTableOptions(s => ({ ...s, page: item, offset: (item - 1) * s.limit.value }));
    } else if (['limit', 'search', 'period'].includes(field)) {
      setTableOptions(s => ({ ...s, [field]: item, offset: 0, page: 1 }));
    } else {
      setTableOptions(s => ({ ...s, [field]: item }));
    }
  };
  const setSorting = sort => onChangeFilter('sorting', sort);
  const setSearch = e => onChangeFilter('search', e.target.value);
  const setPeriod = date => onChangeFilter('period', date);

  const getTableData = () => {
    setLoading(true);
    getRecruiterSSCTableDashboardAPI(dashboard, {
      limit: tableOptions.limit.value,
      offset: tableOptions.offset,
      sorting_field: tableOptions.sorting.field,
      sorting: tableOptions.sorting.order,
      search: tableOptions.search,
      creation_date_after: tableOptions.period?.[0]?.format('YYYY-MM-DD'),
      creation_date_before: tableOptions.period?.[1]?.format('YYYY-MM-DD'),
    })
      .then(d => {
        setTableData(d.results);
        setTableOptions(o => ({ ...o, itemsCount: d.count }));
        setCount(d.count);
      })
      .catch(() => {
        setTableData([]);
        setCount(0);
        setTableOptions(o => ({ ...o, itemsCount: 1 }));
        setTableOptions(INITIAL_VALUES_SSC);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  const onAssign = () => {
    getTableData();
    setAssignPopup(null);
  };

  const onReviewComplete = () => {
    reviewCandidate(reviewPopup.uuid).then(() => {
      setReviewPopup(null);
      notification({ message: 'Candidate has been marked as reviewed.', type: NOTIFICATION_TYPES.SUCCESS });
      getTableData();
    });
  };

  const onDelete = () => {
    deleteCandidate(deletePopup.uuid, true).then(() => {
      setDeletePopup(null);
      getTableData();
    });
  };

  useEffect(() => {
    dispatch(
      getListHrManagers({
        role: [ROLES.RECRUITER, ROLES.RECRUITER_LEAD, ROLES.HR_LEAD],
        my_recruiters_group: user.active_role.name === ROLES.RECRUITER_LEAD,
        ordering: 'full_name',
      })
    );
  }, []);

  useEffect(getTableData, [
    tableOptions.sorting,
    tableOptions.limit,
    tableOptions.offset,
    tableOptions.page,
    tableOptions.search,
    tableOptions.period,
  ]);

  return (
    <>
      <Table
        rowKey="uuid"
        className="self-submitted-candidates"
        cardTitle="Self-submitted candidates"
        stickyHeader
        cardCount={count}
        emptyComponent={
          <Empty hasSearch={tableOptions.search || tableOptions.period?.[0] || tableOptions.period?.[1]} />
        }
        extra={
          <div className="self-submitted-candidates__header-extra">
            <Input type="search" placeholder="Search by name, vacancy" onDebounceChange={setSearch} />
            <RangePicker value={tableOptions.period} onChange={setPeriod} />
            <Button type={BUTTON_TYPES.PRIMARY} onClick={onOpenConfirmPopup} disabled={!selectedRows.length}>
              Delete
            </Button>
          </div>
        }
        columns={COLUMNS_SSC(tableOptions.sorting, setSorting, onAction)}
        loading={loading}
        data={tableData}
        limit={tableOptions.limit}
        page={tableOptions.page}
        itemsCount={tableOptions.itemsCount}
        updateParams={onChangeFilter}
        rowSelection={{
          type: 'checkbox',
          selectedRowKeys: selectedRows.map(r => r.uuid),
          onChange: (_, rows) => setSelectedRows(rows),
        }}
      />
      <TableMobile
        className="self-submitted-candidates_mobile"
        data={tableData}
        columns={COLUMNS_SSC(tableOptions.sorting, setSorting, onAction)}
        empty={<Empty hasSearch={tableOptions.search || tableOptions.period?.[0] || tableOptions.period?.[1]} />}
      />
      <div className="self-submitted-candidates_mobile-footer">
        <TableFooter
          limit={tableOptions.limit}
          page={tableOptions.page}
          onChangePage={value => onChangeFilter('page', value)}
          onChangeLimit={value => onChangeFilter('limit', value)}
          itemsCount={tableOptions.itemsCount}
          loading={loading}
          newLoading
        />
      </div>
      <PopupReassignCandidate
        candidate={assignPopup || {}}
        open={!!assignPopup}
        onAssign={onAssign}
        onClose={onAssignPopupClose}
        candidateJobs={[]}
        isSSC
      />
      <Popup
        open={!!reviewPopup}
        title="Mark candidate as reviewed"
        cancelButtonProps={{
          children: 'cancel',
          onClick: onReviewPopupClose,
        }}
        okButtonProps={{
          children: 'mark',
          onClick: onReviewComplete,
        }}
      >
        <Typography.Text>{`Do you really want to mark ${reviewPopup?.full_name_latin} as reviewed?`}</Typography.Text>
      </Popup>
      <DeleteCandidatePopup
        open={!!deletePopup}
        candidateData={deletePopup}
        onClose={onDeletePopupClose}
        onDelete={onDelete}
      />
      <PopupCandidateDuplicate
        open={!!duplicatesPopup}
        candidateDuplicates={potentialDuplicates}
        duplicate={duplicatesPopup}
        onClose={onDuplicatesPopupClose}
        loading={duplicatesPopup && !potentialDuplicates.length}
      />
      <Popup
        className="popup-delete-ssc"
        open={openConfirmPopup}
        title="Delete candidates?"
        cancelButtonProps={{ onClick: onCloseConfirmPopup, children: 'Back to page' }}
        okButtonProps={{ onClick: onBulkDelete, children: 'Delete' }}
      >
        Are you sure you want to delete{' '}
        <b>
          {selectedRows.length} candidate{selectedRows.length % 10 !== 1 ? 's' : ''}
        </b>
        ?
      </Popup>
    </>
  );
};
