import { stepsStatuses } from 'constants/constants';

export const TABS = [
  {
    label: 'Active candidates',
    key: 'candidates',
  },
  {
    label: 'Self-submitted candidates',
    key: 'ssc',
  },
];

export const CANDIDATES_GROUP = [
  { value: '', label: 'None', tableData: '' },
  { value: 'actions', label: 'Action', tableData: 'actions' },
  { value: 'jobs', label: 'Job', tableData: 'jobs' },
  { value: 'steps', label: 'Step', tableData: 'steps' },
];

export const INITIAL_VALUES = {
  sorting: { key: 'candidate', order: 'asc', field: 'candidate' },
  limit: { value: 30, label: 30 },
  page: 1,
  offset: 0,
  itemsCount: 0,
  only_with_actions: false,
};

export const BUTTON_LABELS = {
  [stepsStatuses.waitForResolution]: 'Submit Decision',
  [stepsStatuses.waitingForFinalDecision]: 'Submit Final Decision',
  [stepsStatuses.provideFeedback]: 'Provide Feedback',
  [stepsStatuses.scheduleInterview]: 'Schedule Interview',
  [stepsStatuses.sendOffer]: 'Add Offer',
  [stepsStatuses.receiveFeedback]: 'Submit Decision',
};
