import React from 'react';
import cn from 'classnames';
import { Button, BUTTON_TYPES, Tooltip, AtsSkeleton } from '_atoms';
import { Typography } from '_atoms/Typography';
import { ReactComponent as Info } from 'dist/icons/info.svg';
import { ReactComponent as Sort } from 'dist/icons/arrow_ascending_descending.svg';
import './style.scss';

const TableHeader = ({
  title,
  subtitle,
  count,
  tooltipInfo,
  loading,
  sorting,
  onSorting,
  extra,
  newLoading,
  sticky,
}) => {
  if (loading && !newLoading) {
    return (
      <div className="table-default__header table-default__header_loading">
        <AtsSkeleton height={36} />
      </div>
    );
  }
  return (
    <div className={cn('table-default__header', { loading }, { sticky })}>
      <div className="table-info">
        <div className="table-title-wrapper">
          <Typography.Title2 className="title">
            {title}
            {tooltipInfo && (
              <Tooltip label={tooltipInfo}>
                <Info className="info" />
              </Tooltip>
            )}
            {!!count && <Typography.Title2 className="table-counter">{count}</Typography.Title2>}
          </Typography.Title2>
          {subtitle && <Typography.Text className="subtitle">{subtitle}</Typography.Text>}
        </div>
      </div>
      <div className="table-extra">
        {sorting && (
          <Button
            className={cn('table-default__header_sorting', sorting.value)}
            onClick={onSorting}
            icon={<Sort className={sorting.value} />}
            type={BUTTON_TYPES.GHOST}
          >
            {sorting.label}
          </Button>
        )}
        {extra}
      </div>
    </div>
  );
};

export default TableHeader;
